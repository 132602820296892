import { AdminVisualizationPicker } from 'client/admin/track/components/admin-visualization-picker';
import { EmbedDataPointContext } from 'client/shared/components/visualization-picker';
import { errorLogger } from 'client/shared/core/error-handler';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { useComparisonGroupFipsShapes } from 'client/shared/hooks/use-comparison-group-fips-shapes';
import { useTrackVariables } from 'client/shared/hooks/use-track-variables';
import {
  ActivationState,
  BenchmarkFilter,
  NationalDataFips,
  StatisticType,
  VisualizationType,
  wrap,
} from 'core';
import _ from 'lodash';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { EmbeddedFileUpload } from '../embedded-file-upload';
import { AiAssistantId } from '@polco-us/types';

/** Render markdown that may contain embedded content.
 *
 * `markdown-to-jsx` passes the props as raw strings, thus the format is very particular.
 * https://www.npmjs.com/package/markdown-to-jsx#passing-props-to-stringified-react-components
 * Valid example:
 *
 * ```md
 * Here is some other content in the markdown. Blah blah. Cats are cool.
 *
 * <EmbeddedContent
 *   type={indicator}
 *   data={{ "variable": "unemployment_rate", "variableType": "INDICATOR" }}
 * />
 * ```
 *
 * `type` is a `string` *without* quotes, and `data` is a JSON object that we can later `JSON.parse`. A newline after the opening `{{` breaks parsing, so the JSON should not be formatted/prettified.
 */
export const MarkdownWithEmbeddedContent: React.FC<{
  readonly className?: string;
  readonly pubId: ClientPublishingEntityId;
  readonly fipsId?: string;
  readonly children: string & React.ReactNode;
  readonly assistantId: AiAssistantId;
  readonly sessionId: string;
}> = (props) => {
  return (
    <Markdown
      className={props.className}
      options={{
        overrides: {
          EmbeddedContent: {
            component: EmbeddedComponent,
            props: {
              pubId: props.pubId,
              fipsId: props.fipsId,
              sessionId: props.sessionId,
              assistantId: props.assistantId,
            },
          },
        },
      }}
    >
      {props.children}
    </Markdown>
  );
};

enum EmbeddedContentType {
  INDICATOR = 'indicator',
  FILE = 'file',
}

interface EmbeddedIndicator {
  readonly variable: string;
  readonly variableType: StatisticType;
  readonly comparisonGroupId?: string;
}

// We use an intermediate component so that the "API" remains stable
export const EmbeddedComponent: React.FC<{
  readonly type: EmbeddedContentType;
  readonly data: string; // JSON
  readonly pubId: ClientPublishingEntityId;
  readonly fipsId?: string;
  readonly assistantId: AiAssistantId;
  readonly sessionId: string;
}> = (props) => {
  const parsedData = JSON.parse(props.data);
  if (props.type === EmbeddedContentType.INDICATOR) {
    // Beware `data` is not validated
    const data = isEmbeddedIndicatorData(parsedData) ? parsedData : null;
    if (!data) {
      errorLogger.log(
        `AI Visualization Error. Invalid embedded component data provided. Session: ${props.sessionId}.`
      );
      return null;
    }
    return (
      <div className="py-1">
        <EmbeddedIndicator
          comparisonGroupId={data.comparisonGroupId}
          fipsId={props.fipsId ?? NationalDataFips}
          pubId={props.pubId}
          sessionId={props.sessionId}
          variable={{
            name: data.variable,
            type: data.variableType,
          }}
        />
      </div>
    );
  }
  if (props.type === EmbeddedContentType.FILE) {
    return (
      <div className="my-3 pt-1">
        <EmbeddedFileUpload
          assistantId={props.assistantId}
          sessionId={props.sessionId}
        />
      </div>
    );
  }

  errorLogger.log(`Unknown EmbeddedContent type: ${props.type}`);
  return null;
};

function isEmbeddedIndicatorData(data: any): data is EmbeddedIndicator {
  if (data?.variable && data?.variableType) {
    return true;
  }
  return false;
}

const EmbeddedIndicator: React.FC<{
  readonly pubId: ClientPublishingEntityId;
  readonly fipsId: string;
  readonly comparisonGroupId?: string;
  readonly variable: {
    readonly name: string;
    readonly type: StatisticType;
  };
  readonly sessionId: string;
}> = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const {
    error: mapError,
    loading: mapLoading,
    fipsShapeByFipsCode,
    boundingCoordinates,
    allFips,
  } = useComparisonGroupFipsShapes(props.comparisonGroupId);

  const uniqueFips = _.uniq([...allFips, props.fipsId]);
  const { trackVariables, loading, error } = useTrackVariables({
    pubId: props.pubId,
    variableNames: [props.variable.name],
    type: props.variable.type,
    fipsCodes: uniqueFips,
  });

  if (loading || mapLoading) {
    // Does not render nicely in-line due to `position: absolute`
    // return <LoaderBars />;
    return null;
  } else if (error || !trackVariables[0]) {
    const errorMessage = error ? error?.message : `No performance data found.`;
    errorLogger.log(
      `AI Visualization Error. Session: ${props.sessionId}. Error: ${errorMessage}`
    );
    return null;
  } else if (props.comparisonGroupId && mapError) {
    errorLogger.log(
      `AI Visualization Error. Error retrieving map data for comparison group: ${props.comparisonGroupId}`,
      mapError
    );
    return null;
  }

  const trackVariable = trackVariables[0];

  // Should be selectable eventually
  const visualizationType = wrap(() => {
    if (trackVariable.statisticType === StatisticType.INDICATOR) {
      return VisualizationType.LINE;
    } else if (trackVariable.statisticType === StatisticType.INDEX) {
      return VisualizationType.HISTOGRAM;
    } else if (trackVariable.statisticType === StatisticType.SENTIMENT_VALUE) {
      return VisualizationType.PROGRESS;
    }
  });

  if (!visualizationType) {
    errorLogger.log(
      `AI Visualization Error. Unknown variable statistic type: ${trackVariable.statisticType}`
    );
    return null;
  }

  if (!trackVariable.areasData.length) {
    errorLogger.log(
      `AI Visualization Error. No areas data for returned visualization. Fips: ${props.fipsId} - Variable: ${trackVariable.id}`
    );
    return null;
  }

  return (
    <AdminVisualizationPicker
      embedContext={EmbedDataPointContext.POLLY}
      expanded={expanded}
      toggleExpanded={() => setExpanded((prev) => !prev)}
      visualizationData={{
        comparisonGroupId: props.comparisonGroupId,
        recordedAt: null,
        benchmarkFilter: BenchmarkFilter.DEFAULT,
        baseFips: props.fipsId ?? null,
        state: ActivationState.ACTIVE,
        visualizationType: visualizationType,
        trackVariable,
        mapData: {
          fipsAreasShapes: fipsShapeByFipsCode,
          mapBoundCoordinates: boundingCoordinates,
        },

        publishingEntityId: props.pubId,
      }}
    />
  );
};
