import React from 'react';
import { useAdminPermissions } from 'client/admin/hooks/use-admin-permissions';
import { PermissionType } from '@polco-us/types';
import { useSavedDataPoints } from 'client/admin/hooks';
import { useTrackGoals } from 'client/admin/hooks/use-track-goals';
import _ from 'lodash';
import { ContentUnavailableType } from 'client/shared/components/content-unavailable';
import { VisualizationUnavailable } from 'client/shared/components/visualization-picker/not-found';
import {
  VisualizationData,
  EmbedDataPointContext,
  VisualizationPicker,
} from 'client/shared/components/visualization-picker';

interface AdminVisualizationPickerProps {
  readonly visualizationData: VisualizationData | null;
  readonly expanded: boolean;
  readonly toggleExpanded?: () => void;
  readonly label?: string;
  readonly showInactive?: boolean;
  readonly embedContext: EmbedDataPointContext;
}

export const AdminVisualizationPicker: React.FC<AdminVisualizationPickerProps> = (
  p
) => {
  if (!p.visualizationData) {
    return (
      <VisualizationUnavailable
        label={p.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }
  return (
    <AdminVisualizationPickerContent
      visualizationData={p.visualizationData}
      {..._.omit(p, 'visualizationData')}
    />
  );
};
AdminVisualizationPicker.displayName = 'AdminVisualizationPicker';

const AdminVisualizationPickerContent: React.FC<
  AdminVisualizationPickerProps & { readonly visualizationData: VisualizationData }
> = (p) => {
  const { doesCurrentAdminHavePermissions: havePermissions } = useAdminPermissions();
  const canManageSavedDataPoints = havePermissions([
    PermissionType.MANAGE_SAVED_DATA_POINTS,
  ]);

  const { saveVisualization, activeVisualizations, publisherMax } =
    useSavedDataPoints({
      publishingEntityId: p.visualizationData.publishingEntityId,
      loadVisualizationData: false,
    });

  const { strategicPlan } = useTrackGoals({
    publishingEntityId: p.visualizationData.publishingEntityId,
  });
  const currentGoal = strategicPlan?.goals.find(
    (goal) => goal.variable.id === p.visualizationData?.trackVariable.id
  );

  return (
    <VisualizationPicker
      {...p}
      disableSaveDataPoints={
        !publisherMax || activeVisualizations.length >= publisherMax
      }
      goal={currentGoal}
      hasManageSavedDataPointsPerm={canManageSavedDataPoints}
      saveVisualization={saveVisualization}
      strategicPlanId={strategicPlan?.id}
    />
  );
};
